import { CloseCircle } from '@styled-icons/remix-line';
import { CloseCircle as CloseCircleFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function CloseIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <CloseCircle />
    </Icon>
  );
}

export function CloseFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <CloseCircleFill />
    </Icon>
  );
}
